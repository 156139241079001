import React from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import BlockModel from '../../../../models/block.model';
import EntitySeasonsSelectModel from '../../../../partials/player-seasons/model/entity-seasons-select.model';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import TeamSelectComponent from '../../../partials/team-select/team-select.component';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { SPORT_SELECT_ATTRIBUTES } from '../../helpers/component-attributes.constants';
import { createBlock } from '../../../partials/helpers/blocky-sidebar-communication/blocky-sidebar-communication.helper';
import { WidgetsV2Ids } from '../../../../constants/block.types';
import { ElementsSelect } from '../../../partials/elements-select/widget-elements-select';
import { teamMatchStatsOptions } from '../../football-team/constants/football-team.constants';
import FootballTeamH2hMatchWidgetModel from '../models/football-team-match-stats.model';
import { featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import { mapMatchTournamentSeasonStageToModel } from '../../../partials/helpers/player-select/player-season-select-component.helper';
import { OddsEditV2Component } from '../../odds/components/v2/odds-v2-block.component';
import OddsV2Model from '../../odds/models/v2/odds-v2.model';
import { buildInitialModel } from '../../odds/helper/odds.helper';
import { MATCH_STATUS_TYPES } from '../../../partials/matches/matches-v2/matches-container-v2.helper';
import { SelectMenuOptionType } from '../../../../../../../models/v2/general/select.model';
import { onRefreshTimeChangeFromMainComponent } from '../../../partials/refresh-time-input/helper';
import RefreshTimeWrapper from '../../../partials/refresh-time-input/refresh-time-wrapper';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import MatchModel from '../../../../../../../models/v2/match/entity/match.model';
import { MatchesContainer } from '../../../partials/matches/matches-container';
import HttpService from '../../../../../../../services/rest/HttpService';
import ErrorHandler from '../../../partials/error-handler/error-handler';

type Properties = {
	block: BlockModel;
	t: any;
	contentData: any;
	contentLanguage: string;
};

type State = {
	widgetModel: FootballTeamH2hMatchWidgetModel;
	oddsModel: OddsV2Model;
	isValid: boolean;
};

export default class FootballTeamH2hMatchEditComponent extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		const oddsModel = buildInitialModel(props.block.data.preview && props.block.data.preview.odds, {} as MatchModel);
		this.state = {
			widgetModel: createBlock(
				WidgetsV2Ids.widgetFootballTeamH2HMatch,
				props.block.data.preview && props.block.data.preview.widgetModel,
				props.contentData,
				props.block,
			),
			oddsModel: oddsModel,
			isValid: true,
		};
	}

	componentDidMount(): void {
		const { widgetModel } = this.state;
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });

		// If match is prefilled from sidebar, retrieve the season and tournament data
		if (widgetModel.match && widgetModel.match.id && !widgetModel.team1TournamentSeason.seasonId) {
			this.onMatchChange(widgetModel.match);
		}
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>, snapshot?: any) {
		if (
			this.state.widgetModel.displayOdds !== prevState.widgetModel.displayOdds &&
			!this.state.widgetModel.displayOdds &&
			!this.state.widgetModel.match
		) {
			const emptyOddsModel = new OddsV2Model();

			this.setState({ ...this.state, oddsModel: emptyOddsModel });
		}
	}

	onSave = (index: number) => {
		const validation = new BlockValidation();
		const data = { widgetModel: this.state.widgetModel, oddsModel: this.state.oddsModel };
		const isValid = validation.validate(this.props.block, data) === true;

		this.setState({ ...this.state, isValid });

		const bookmakerSelectionEnabled = featuresService && featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);
		const odds = this.state.oddsModel;
		odds.mainBookmaker = bookmakerSelectionEnabled ? this.state.oddsModel.mainBookmaker : new SportOddsBookmakerModel();

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { widgetModel: this.state.widgetModel, odds };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	updateWidgetModelState = (model: FootballTeamH2hMatchWidgetModel) => {
		this.setState({ ...this.state, widgetModel: model });
	};

	onTeam1Change = (team: TeamModel) => {
		this.updateWidgetModelState(
			FootballTeamH2hMatchWidgetModel.builder(this.state.widgetModel)
				.withTeamOne(team)
				.withTeamOneSeason({} as EntitySeasonsSelectModel)
				.withDisplayOdds(false)
				.withMatch(new MatchModel())
				.build(),
		);
		const oddsModel = new OddsV2Model();
		this.setState({
			oddsModel,
		});
	};

	onTeam2Change = (team: TeamModel) => {
		this.updateWidgetModelState(
			FootballTeamH2hMatchWidgetModel.builder(this.state.widgetModel)
				.withTeamTwo(team)
				.withTeamTwoSeason({} as EntitySeasonsSelectModel)
				.withDisplayOdds(false)
				.withMatch(new MatchModel())
				.build(),
		);
		const oddsModel = new OddsV2Model();
		this.setState({
			oddsModel,
		});
	};

	onStatsParametersChange = (statsParameters: string[]) => {
		this.updateWidgetModelState(FootballTeamH2hMatchWidgetModel.builder(this.state.widgetModel).withStats(statsParameters).build());
	};

	onMatchChange = (match: MatchModel) => {
		let seasonData = {};
		if (match && match.id) {
			HttpService.getMatchById(match.id).then((response: any) => {
				seasonData = response.data && response.data.season;

				const tournamentSeasonData = mapMatchTournamentSeasonStageToModel(match, seasonData);
				const widgetModel = FootballTeamH2hMatchWidgetModel.builder(this.state.widgetModel)
					.withMatch(match)
					.withTeamOneSeason(tournamentSeasonData)
					.withTeamTwoSeason(tournamentSeasonData)
					.withDisplayOdds(true)
					.build();
				const oddsModel = new OddsV2Model();

				this.setState({
					...this.state,
					widgetModel,
					oddsModel,
				});
			});
		}
	};

	onDisplayOddsChange = (event: any) => {
		this.updateWidgetModelState(
			FootballTeamH2hMatchWidgetModel.builder(this.state.widgetModel).withDisplayOdds(event.target.checked).build(),
		);
	};

	onOddsWidgetChange = (oddsModel: OddsV2Model) => this.setState({ ...this.state, oddsModel });

	onRefreshTimeChange = (refreshTimeData: SelectMenuOptionType) => {
		onRefreshTimeChangeFromMainComponent(
			refreshTimeData,
			FootballTeamH2hMatchWidgetModel,
			this.state.widgetModel,
			this.updateWidgetModelState,
		);
	};

	render() {
		const { widgetModel, isValid, oddsModel } = this.state;
		const { t } = this.props;
		const teams = [widgetModel.team1, widgetModel.team2];

		return (
			<div data-qa='football-team-h2h-match-block-edit'>
				<Row>
					<Col>
						<TeamSelectComponent
							isMulti={false}
							isBlocky
							isValid={isValid}
							selectedTeam={widgetModel.team1 ? widgetModel.team1 : {}}
							onTeamSelect={(team: TeamModel) => this.onTeam1Change(team)}
							t={t}
							isClearable
							isRequired={true}
							searchByKeyword={true}
							id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.TEAM_SELECT}-1`}
						/>
					</Col>
				</Row>
				<hr />
				<Row>
					<Col>
						<TeamSelectComponent
							isMulti={false}
							isBlocky
							isValid={isValid}
							selectedTeam={widgetModel.team2 ? widgetModel.team2 : {}}
							onTeamSelect={(team: TeamModel) => this.onTeam2Change(team)}
							t={t}
							isClearable={true}
							isRequired
							searchByKeyword={true}
							id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.TEAM_SELECT}-2`}
						/>
					</Col>
				</Row>
				<hr />
				{widgetModel.team1 && widgetModel.team2 && (
					<Row className='mb-2'>
						<Col>
							<MatchesContainer
								t={t}
								teamIds={teams && teams.length > 0 ? teams.map((team: TeamModel) => team.id) : []}
								isMulti={false}
								maxNotStartedMatches={20}
								maxFinishedMatches={20}
								maxInterruptedMatches={20}
								teamNames={teams && teams.length > 0 ? teams.filter((team) => team).map((team: TeamModel) => team.name) : ['']}
								selectedMatchId={widgetModel && widgetModel.match ? widgetModel.match.id : null}
								onSelect={this.onMatchChange}
							/>
						</Col>
					</Row>
				)}
				<div className='mb-3 mt-2'>
					<ErrorHandler
						arePropsValid={isValid}
						isValueSelected={widgetModel.match === undefined || widgetModel.match === null || !widgetModel.match.id}
						t={t}
						errorMessage='no_matches_selected'
					/>
				</div>
				{widgetModel.team1TournamentSeason &&
					widgetModel.team2TournamentSeason &&
					widgetModel.team1TournamentSeason.seasonId &&
					widgetModel.team2TournamentSeason.seasonId && (
						<Row className='mt-2' data-qa='football-team-match-stats-elements-select'>
							<Col>
								<FormGroup>
									<Label htmlFor={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.ELEMENTS}`}>{t('select_stats')}</Label>
									<ElementsSelect value={widgetModel.stats} onSelect={this.onStatsParametersChange} t={t} isMulti elements={teamMatchStatsOptions} />
								</FormGroup>
							</Col>
						</Row>
					)}
				{widgetModel.match &&
					widgetModel.match.eventStatus &&
					widgetModel.match.eventStatus.code !== MATCH_STATUS_TYPES.finished &&
					widgetModel.match.id &&
					widgetModel.match.id > 0 && (
						<Row className='mb-2'>
							<Col>
								<FormGroup key={`display-odds-markets`} check className='radio'>
									<Input
										key={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.DISPLAY_ODDS_SELECT}`}
										className='form-check-input'
										type='checkbox'
										id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.DISPLAY_ODDS_SELECT}-${this.props.block.id}`}
										name='radios'
										onChange={this.onDisplayOddsChange}
										checked={widgetModel.displayOdds ? widgetModel.displayOdds : false}
										data-qa={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.DISPLAY_ODDS_SELECT}`}
									/>
									<Label
										check
										htmlFor={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.DISPLAY_ODDS_SELECT}`}
										key='display-odds-markets-label'
										className='form-check-label'
										for={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.DISPLAY_ODDS_SELECT}-${this.props.block.id}`}
									/>
									{this.props.t('display_odds')}
								</FormGroup>
							</Col>
						</Row>
					)}
				{widgetModel.displayOdds && (
					<OddsEditV2Component
						widgetType={WidgetsV2Ids.widgetFootballTeamH2HMatch}
						block={this.props.block}
						isStandAlone={false}
						t={t}
						match={widgetModel.match && widgetModel.match.id ? widgetModel.match : ({} as MatchModel)}
						onOddsWidgetChange={this.onOddsWidgetChange}
						displayOddsLabel={true}
						isValid={isValid}
						currentOdds={oddsModel}
					/>
				)}
				<RefreshTimeWrapper value={widgetModel.refreshTime} onRefreshTimeChange={this.onRefreshTimeChange} />
			</div>
		);
	}
}
